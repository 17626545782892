// import dependencies normally
import React, { useEffect, useState } from "react"
import Reaptcha from "reaptcha"
import {
  makeRem,
  Form,
  FormInput,
  FormSubmit,
  Spinner,
  Text,
} from "@thedgbrt/logology-ui-components"
import { FormError } from "../../common/Form"
import useSignUpForm from "./useSignUpForm"
import { getRecaptchaKey } from "./helpers"

const SignUpForm = ({ initEmail, onSuccess }) => {
  let formRef = React.createRef()
  let inputEmailRef = React.createRef()
  let inputPasswordRef = React.createRef()
  let recaptchaRef = React.createRef()

  const [isClient, setClient] = useState(false)

  useEffect(() => {
    setClient(true)
  }, [])

  // force load recaptcha to avoid bugs on safari
  useEffect(() => {
    if (recaptchaRef && recaptchaRef.current && recaptchaRef.current.state) {
      if (
        recaptchaRef.current.state.ready &&
        !recaptchaRef.current.state.rendered
      )
        recaptchaRef.current.renderExplicitly()
    }
  }, [recaptchaRef])

  const {
    error,
    inputs,
    inputErrors,
    handleInputChange,
    handleRecaptchaLoad,
    handleRecaptchaVerify,
    handleSubmit,
    loading,
  } = useSignUpForm(initEmail, onSuccess)

  const onSubmit = e => {
    handleSubmit(
      e,
      [inputEmailRef, inputPasswordRef],
      recaptchaRef.current,
      formRef
    )
  }

  return (
    <form
      id="sign-up-form"
      left
      ref={formRef}
      style={{ paddingTop: makeRem(12) }}
    >
      {loading ? (
        <>
          <Spinner standalone />
          {/* @forgiveme: dirty spacer */}
          &nbsp;
        </>
      ) : (
        <>
          <FormError error={error} />
          <Form left standalone>
            <FormInput
              autoComplete="email"
              placeholder="My email address&hellip;"
              type="email"
              name="email"
              error={inputErrors.email}
              onChange={e => handleInputChange(e, inputEmailRef)}
              value={inputs.email}
              ref={inputEmailRef}
              required
            />
            <FormInput
              autoComplete="new-password"
              autoFocus // eslint-disable-line
              placeholder="create your password"
              type="password"
              name="password"
              error={inputErrors.password}
              onChange={e => handleInputChange(e, inputPasswordRef)}
              value={inputs.password}
              ref={inputPasswordRef}
              required
            />

            {/* reaptcha can have issues on safari if it loads from the server first (direct link access) */}
            {isClient ? (
              <Reaptcha
                onError={e => console.log(e)}
                onLoad={handleRecaptchaLoad}
                onVerify={handleRecaptchaVerify}
                ref={recaptchaRef}
                sitekey={getRecaptchaKey()}
                size="invisible"
                explicit
              />
            ) : null}
            <FormSubmit
              text="sign up →"
              id="sign-up-submit"
              onClick={onSubmit}
            />
          </Form>
          <Text standalone className="small-text">
            <em>
              This site is protected by reCAPTCHA and the Google{" "}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy policy
              </a>{" "}
              and{" "}
              <a
                href="https://policies.google.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms of service
              </a>{" "}
              apply.
            </em>
          </Text>
        </>
      )}
    </form>
  )
}

export default SignUpForm
