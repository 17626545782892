import React, { useState } from "react"
import { connect } from "react-redux"
import {
  makeRem,
  GridContainer,
  GridBox,
  Separator,
  Standalone,
  Title,
} from "@thedgbrt/logology-ui-components"
import AlreadyLoggedIn from "./AlreadyLoggedIn"
import SignUpForm from "./SignUpForm"
import SignUpWithGoogle from "./SignUpWithGoogle"
import SignUpConfirm from "./SignUpConfirm"
import { getUriParameterByName } from "../../../helpers"
import { trackSegmentEvent } from "../../../helpers/segment"
import { trackAmplitude } from "../../../helpers/amplitude"
import("./styles.css")

const SignUp = ({ isLoggedIn, url }) => {
  const initEmail = getUriParameterByName("email", url)
  const displayType = getUriParameterByName("type", url)

  const [confirm, setConfirm] = useState(false)
  const [email, setEmail] = useState("")
  const [pw, setPw] = useState("")

  const handleCancelVerification = () => {
    setEmail("")
    setPw("")
    setConfirm(false)
  }

  const handleSignUpSuccess = async (email, pw) => {
    trackSegmentEvent("track", "Submit signup form")
    trackAmplitude("Submit signup form")
    setEmail(email)
    setPw(pw)
    setConfirm(true)
  }

  const renderTitle = type => {
    if (type === "limit_reached")
      return "Create a free account to keep viewing logos"
    else if (type === "email_sequence")
      return "Create a free account to get your free branding course"
    else if (type === "save_logo")
      return "Create a free account to save your favorite logos"
    return "Save proposals to your account"
  }

  if (isLoggedIn) return <AlreadyLoggedIn />

  const renderInner = () => {
    if (confirm) {
      return (
        <SignUpConfirm
          email={email}
          pw={pw}
          handleCancelVerification={handleCancelVerification}
        />
      )
    } else {
      return (
        <>
          <Title level={4} standalone>
            {renderTitle(displayType)}
          </Title>
          <SignUpForm initEmail={initEmail} onSuccess={handleSignUpSuccess} />

          <Standalone>
            <Separator>or</Separator>
          </Standalone>

          <Standalone style={{ padding: `${makeRem(15)} 0` }}>
            <SignUpWithGoogle small />
          </Standalone>
        </>
      )
    }
  }

  return (
    <>
      <GridContainer
        rowsDesktop={["auto"]}
        rowsMobile={["auto"]}
        rowsTablet={["auto"]}
        type={3}
      >
        <GridBox
          colSpans={[[2, 6], [2, 4], [3, 4]]}
          withBorder
          type={3}
          hideBorderRight={[true, false, false]}
        >
          {renderInner()}
        </GridBox>
      </GridContainer>
      <GridContainer
        rowsDesktop={[makeRem(49)]}
        rowsMobile={[makeRem(30)]}
        rowsTablet={[makeRem(30)]}
        type={3}
      />
    </>
  )
}

export default connect(
  ({ user }) => ({
    isLoggedIn: user.isLoggedIn,
  }),
  null
)(SignUp)
