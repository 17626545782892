import React from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import { Button, makeRem, Text } from "@thedgbrt/logology-ui-components"
import { signOutUser } from "../../../state/actions/user"
import Auth from "@aws-amplify/auth"

const AlreadyLoggedIn = ({ email, signOutUser }) => {
  const handleSignOut = async () => {
    await signOutUser(Auth)
    navigate("/signup")
  }

  return (
    <>
      <Text standalone>You're already logged in as {email}.</Text>
      <Text
        className="small-text"
        standalone
        style={{ marginBottom: makeRem(18) }}
      >
        <Button small onClick={handleSignOut}>
          sign out
        </Button>
        <Button small simple onClick={() => navigate("/myaccount/settings")}>
          Manage my account
        </Button>
      </Text>
    </>
  )
}

export default connect(
  ({ user }) => ({ email: user.email }),
  { signOutUser }
)(AlreadyLoggedIn)
