import React from "react"
import SignUp from "../../components/auth/SignUp"
import RedirectRoute from "../../components/auth/RedirectRoute"

const SignUpPage = ({ location }) => (
  <RedirectRoute to="/myaccount/proposals/">
    <SignUp url={location.href} />
  </RedirectRoute>
)

export default SignUpPage
