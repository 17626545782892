import React from "react"
import SendVerificationCode from "../SendVerificationCode"

const SignUpConfirm = ({ handleCancelVerification, email, pw }) => {
  return (
    <SendVerificationCode
      email={email}
      pw={pw}
      onCancel={handleCancelVerification}
      sendRightAway={false}
    />
  )
}

export default SignUpConfirm
