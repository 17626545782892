import { useEffect, useState } from "react"
import Auth from "@aws-amplify/auth"
import { validateInput, validateInputs } from "../../../helpers/validation"

const useSignUpForm = (initEmail, successCallback) => {
  const [error, setError] = useState({
    message: "",
    exists: false,
  })

  const [hasValidatedOnce, setHasValidatedOnce] = useState(false)
  const [inputs, setInputs] = useState({
    email: initEmail,
    password: "",
  })
  const [inputErrors, setInputErrors] = useState({
    email: "",
    password: "",
  })
  const [loading, setLoading] = useState(false)

  const [recaptchaStatus, setRecaptchaStatus] = useState({
    loaded: false,
    verified: false,
  })

  useEffect(() => {
    if (recaptchaStatus.verified) handleSubmitAfterRecaptcha()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recaptchaStatus.verified])

  const handleInputChange = (event, ref) => {
    event.persist()
    setInputs(inputs => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }))

    // if we already validated the form once (after first submit try),
    // update validation results in real time
    if (hasValidatedOnce) {
      validateInput(ref, setInputErrors)
    }
  }

  const handleError = ({ code, name, message }, email) => {
    let messageClean = message

    // weird cognito behavior: requires 8 char but the error states 6 sometimes
    if (
      message.indexOf("length greater than or equal to 6") > -1 ||
      message.indexOf("Password not long enough") > -1
    ) {
      messageClean = "Your password must be at least 8 characters."
      setError({ exists: true, message: messageClean })
    } else if (message.indexOf("PreSignUp failed with error ") > -1) {
      messageClean = message.replace("PreSignUp failed with error ", "")
      setError({ exists: true, message: messageClean })
    } else {
      setError({ exists: true, message: message })
    }
  }

  const handleRecaptchaVerify = response => {
    setRecaptchaStatus({
      ...recaptchaStatus,
      verified: true,
    })
  }

  const handleRecaptchaLoad = response => {
    setRecaptchaStatus({
      ...recaptchaStatus,
      loaded: true,
    })
  }

  const handleSubmit = (event, inputRefs, recaptchaRef) => {
    event.preventDefault()
    setHasValidatedOnce(true)

    // validate each field individually
    if (validateInputs(inputRefs, setInputErrors) !== true) return

    // validate captcha
    if (recaptchaStatus.verified !== true) {
      if (
        recaptchaRef &&
        recaptchaRef.state &&
        recaptchaRef.state.rendered === true
      ) {
        recaptchaRef.execute()
      } else {
        setError({
          exists: true,
          message:
            "We're having trouble validating your submission, please refresh the page.",
        })
        return
      }
    } else handleSubmitAfterRecaptcha()
  }

  const handleSubmitAfterRecaptcha = () => {
    setLoading(true)

    // force lowercase to fix aws email case issue
    const username = inputs.email.toLowerCase().trim()

    Auth.signUp({
      username: username,
      password: inputs.password,
    })
      .then(data => {
        setLoading(false)
        successCallback(data.user.username, inputs.password)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
        handleError(err, username)
      })
  }

  return {
    error,
    handleSubmit,
    handleInputChange,
    handleRecaptchaLoad,
    handleRecaptchaVerify,
    inputs,
    inputErrors,
    loading,
  }
}

export default useSignUpForm
